import { render, staticRenderFns } from "./gName-result.vue?vue&type=template&id=52e78224&scoped=true&"
import script from "./gName-result.vue?vue&type=script&lang=js&"
export * from "./gName-result.vue?vue&type=script&lang=js&"
import style0 from "./gName-result.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./gName-result.vue?vue&type=style&index=1&id=52e78224&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e78224",
  null
  
)

export default component.exports